import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import ProductsList from '../components/UI/ProductsList';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';

const Grotto = () => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, "products"));
                const productsList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                const filteredProducts = productsList.filter(item => item.category === 'Grotto');

                // Sort products by type: T-Shirts first, followed by Posters, then Carpets
                const sortedProducts = filteredProducts.sort((a, b) => {
                    const order = { tshirt: 1, poster: 2, carpet: 3 };
                    return order[a.productType] - order[b.productType];
                });
                setData(sortedProducts);
            } catch (error) {
                toast.error("Error fetching products: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    return (
        <div style={{minHeight: '70vh', backgroundColor: '#000'}}>
          <section id='#products' className='section'>
              <Container>
                  <Row className='gallery'>
                      {loading && <div> Loading Products... </div>}
                      <ProductsList data={data} />
                  </Row>
              </Container>
          </section>
        </div>
    )
}

export default Grotto