import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, FormGroup, Button } from 'reactstrap';
import Helmet from '../components/Helmet/Helmet';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { auth, db } from '../firebase.config';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import '../styles/checkout.css';
import { ShippingStatus } from '../components/Helpers/Enums/ShippingStatus';
import { states } from '../assets/data/us_states';
import { toast } from 'react-toastify';
import { functions } from '../firebase.config';
import { httpsCallable } from "firebase/functions"
import 'firebase/functions';
// import firebase from 'firebase/compat/app';

// Load Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEPUBLISHABLEKEY);

const CheckoutForm = ({ shippingCharges, setShippingCharges, shippingAddress, setShippingAddress }) => {
    const [email, setEmail] = useState('');
    const [billingAddress, setBillingAddress] = useState({
        fullName: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        country: 'United States'
    });
    const [isSameAddress, setIsSameAddress] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isAddressValid, setIsAddressValid] = useState(false);

    const getUSPSAccessToken = httpsCallable(functions, 'getAccessToken');

    const requestBody = {
        originZIPCode: 59808,   // Originating ZIP Code
        destinationZIPCode: shippingAddress.zip, // Destination ZIP Code
        weight: 2.5,                // Total weight in pounds
        length: 0,                // Package length in inches
        width: 0,                  // Package width in inches
        height: 0,                // Package height in inches
        mailClass: "USPS_GROUND_ADVANTAGE",          // Mail service requested, e.g., "PRIORITY_MAIL"
        processingCategory: "MACHINABLE", // Processing category, e.g., "MACHINABLE"
        rateIndicator: "SP",  // Rate categorization indicator, e.g., "3D"
        destinationEntryFacilityType: "None", // Facility type, e.g., "DESTINATION_DELIVERY_UNIT"
        priceType: "COMMERCIAL",          // Price type, e.g., "RETAIL"
    };
    const packageData = {
        originZip: "12345",
        weightInOunces: 16,
        containerType: "Box",
        size: "Medium",
        serviceType: "Priority Mail"
    };

    const handleShippingChange = (e) => {
        const { name, value } = e.target;
        setShippingAddress((prev) => ({
            ...prev,
            [name]: value
        }));

        if (isSameAddress) {
            setBillingAddress((prev) => ({
                ...prev,
                [name]: value
            }));
        }

        setIsAddressValid(false);
        setShippingCharges(0);
    };

    const handleBillingChange = (e) => {
        const { name, value } = e.target;
        setBillingAddress((prev) => ({
            ...prev,
            [name]: value
        }));
    };
    const handleCheckboxChange = (e) => {
        setIsSameAddress(e.target.checked);
        if (e.target.checked) {
            setBillingAddress(shippingAddress);
        }
    };

    const fetchShippingPrice = async (packageData) => {
        setLoading(true)
        if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            toast.error("Please enter a valid email address.");
            setLoading(false)
            return;
        }
        if (
            !shippingAddress.fullName || shippingAddress.fullName.length <= 2 ||
            !shippingAddress.street || shippingAddress.street.length <= 2 ||
            !shippingAddress.state || shippingAddress.state.length !== 2 ||
            !shippingAddress.zip || !/^\d{5}$/.test(shippingAddress.zip)
        ) {
            toast.error("Invalid address. Please check your street, state, and ZIP code.");
            setLoading(false)
            return;
        }

        try {
            // Get USPS access token
            const tokenResponse = await getUSPSAccessToken();
            const accessToken = tokenResponse.data.accessToken;

            // Validate the address first
            const validateAddress = httpsCallable(functions, "validateAddress");
            const validatedAddress = await validateAddress({
                accessToken: accessToken,
                streetAddress: shippingAddress.street,
                city: shippingAddress.city,
                state: shippingAddress.state,
                zipCode: shippingAddress.zip,
            });

            if (!validatedAddress.data || !validatedAddress.data.address.ZIPCode) {
                toast.error("Invalid address! Please enter a valid address.");
                setLoading(false);
            }

            const response = await fetch(
                "https://us-central1-the-vision-745ff.cloudfunctions.net/calculateShippingRate",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        accessToken: accessToken,
                        destinationZIPCode: validatedAddress.data.address.ZIPCode,
                        weight: 10,
                    }),
                }
            );

            if (!response.ok) {
                toast.error("Invalid address! Please enter a valid address.");
                setLoading(false);
            }

            const shippingCost = await response.json();
            
            setShippingCharges((Number(shippingCost.shippingPrice) || 0).toFixed(2))
            setLoading(false);
            toast.success("Shipping information added successfully!")
            return shippingCost.shippingPrice;
    
        } catch (error) {
            toast.error("Error fetching shipping price.");
            setLoading(false);
            return;
        }
    };


    const subTotal = useSelector(state => state.cart.totalAmount);
    //const shippingCharges = 20.00;
    const total = Math.round((subTotal + shippingCharges) * 100) / 100;

    const functionUrl = 'https://createpaymentintent-tyiughjr4a-uc.a.run.app'

    const stripe = useStripe();
    const elements = useElements();

    const getCurrentUserId = () => {
        const user = auth.currentUser;
        setEmail(user.email)
        return user ? user.uid : null;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // await ValidateAddress()

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        try {
            const response = await fetch(functionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: total * 100, // amount in cents
                    currency: 'usd',
                }),
            });

            const { clientSecret } = await response.json();

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: billingAddress.fullName,
                        email: email,
                        address: {
                            line1: billingAddress.street,
                            city: billingAddress.street,
                            state: billingAddress.state,
                            postal_code: billingAddress.zip,
                            country: 'US',
                        },
                    },
                },
            });

            if (result.error) {
                console.error(result.error.message);
            } else if (result.paymentIntent.status === 'succeeded') {
                toast.success('Payment Successful!');
                // Store additional information in Firestore
                const userId = getCurrentUserId();
                const purchaseRef = collection(db, "purchases");
                await addDoc(purchaseRef, {
                    id: purchaseRef.id,
                    userId: userId,
                    shippingAddress: shippingAddress,
                    pointsUsed: 0,
                    shippingFee: shippingCharges,
                    shippingStatus: ShippingStatus.PENDING,
                    createdAt: new Date(),
                    updatedAt: null
                });
            }
        } catch (error) {
            console.error("Payment Error:", error);
        }
    };


    return (
        <Form onSubmit={handleSubmit}>
            <div className='form-section'>
                <span className='form-subtitle'>
                    <h6>Customer Info</h6>
                    <p>* Required</p>
                </span>
                <FormGroup className='form-group'>
                    <label>Email *</label>
                    <input
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </FormGroup>
            </div>

            <div className='form-section'>
                <span className='form-subtitle'>
                    <h6>Shipping Address</h6>
                    <p>* Required</p>
                </span>
                <FormGroup className='form-group'>
                    <label>Full Name *</label>
                    <input
                        name='fullName'
                        type='text'
                        value={shippingAddress.fullName}
                        onChange={handleShippingChange}
                        required
                    />
                </FormGroup>
                <FormGroup className='form-group'>
                    <label>Street Address *</label>
                    <input
                        name='street'
                        type='text'
                        value={shippingAddress.street}
                        onChange={handleShippingChange}
                        required
                    />
                </FormGroup>
                <Row>
                    <Col md={4}>
                        <FormGroup className='form-group'>
                            <label>City *</label>
                            <input
                                name='city'
                                type='text'
                                value={shippingAddress.city}
                                onChange={handleShippingChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup className='form-group'>
                            <label>State/Province</label>
                            <select
                                name='state'
                                value={shippingAddress.state}
                                onChange={handleShippingChange}
                                required
                            >
                                <option value="">-- Select a State --</option>
                                {states.map((state) => (
                                    <option key={state.code} value={state.code}>
                                    {state.name}
                                    </option>
                                ))}
                            </select>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup className='form-group'>
                            <label>Zip/Postal Code *</label>
                            <input
                                name='zip'
                                type='text'
                                value={shippingAddress.zip}
                                onChange={handleShippingChange}
                                required
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup className='form-group'>
                    <label>Country *</label>
                    <input
                        name='country'
                        type='text'
                        value={shippingAddress.country}
                        readOnly
                    />
                </FormGroup>

                <Button className="btn-form" type="button" onClick={fetchShippingPrice} disabled={loading || isAddressValid}>
                    {loading ? 'Calculating...' : 'Add Shipping Information'}
                </Button>
            </div>

            <div className='form-section'>
                <span className='form-subtitle'>
                    <h6>Shipping Method</h6>
                </span>
                <FormGroup className='form-group'>
                    <div className='radio-input'>
                        <input
                            type='radio'
                            role='button'
                            checked
                            readOnly
                        />
                        <label>Shipping Charges</label>
                    </div>
                </FormGroup>
            </div>

            <div className='form-section'>
                <span className='form-subtitle'>
                    <h6>Payment Info</h6>
                    <p>* Required</p>
                </span>
                <FormGroup className='form-group'>
                    <label>Card Number *</label>
                    <CardElement />
                </FormGroup>
                <FormGroup className='form-group'>
                    <div className='radio-input'>
                        <input
                            type='checkbox'
                            role='button'
                            checked={isSameAddress}
                            onChange={handleCheckboxChange}
                        />
                        <label>Billing address same as shipping</label>
                    </div>
                </FormGroup>
                <button onClick={fetchShippingPrice}>GetPrice</button>
            </div>

            {!isSameAddress &&
                <div className='form-section billing-address'>
                    <span className='form-subtitle'>
                        <h6>Billing Address</h6>
                        <p>* Required</p>
                    </span>
                    <FormGroup className='form-group'>
                        <label>Full Name *</label>
                        <input
                            name='fullName'
                            type='text'
                            value={billingAddress.fullName}
                            onChange={handleBillingChange}
                            required
                        />
                    </FormGroup>
                    <FormGroup className='form-group'>
                        <label>Street Address *</label>
                        <input
                            name='street'
                            type='text'
                            value={billingAddress.street}
                            onChange={handleBillingChange}
                            required
                        />
                    </FormGroup>
                    <Row>
                        <Col md={4}>
                            <FormGroup className='form-group'>
                                <label>City *</label>
                                <input
                                    name='city'
                                    type='text'
                                    value={billingAddress.city}
                                    onChange={handleBillingChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className='form-group'>
                                <label>State/Province *</label>
                                <select
                                name='state'
                                value={shippingAddress.state}
                                onChange={handleShippingChange}
                                required
                                >
                                    <option value="">-- Select a State --</option>
                                    {states.map((state) => (
                                        <option key={state.code} value={state.code}>
                                        {state.name}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup className='form-group'>
                                <label>Zip/Postal Code *</label>
                                <input
                                    name='zip'
                                    type='text'
                                    value={billingAddress.zip}
                                    onChange={handleBillingChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className='form-group'>
                        <label>Country *</label>
                        <input
                            name='country'
                            type='text'
                            value={billingAddress.country}
                            readOnly
                        />
                    </FormGroup>
                </div>
            }

            <Button type="submit" disabled={loading}>
                {loading ? 'Processing...' : 'Place Order'}
            </Button>
            {error && <div>{error}</div>}
        </Form>
    );
};

const Checkout = () => {
    const [shippingCharges, setShippingCharges] = useState(0);
    const [shippingAddress, setShippingAddress] = useState({
        fullName: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        country: 'United States'
    });
    
    const subTotal = useSelector(state => state.cart.totalAmount);
    const total = Math.round((subTotal + shippingCharges) * 100) / 100;

    console.log("subTotal:", subTotal, "shippingCharges:", shippingCharges);
console.log("Type of subTotal:", typeof subTotal, "Type of shippingCharges:", typeof shippingCharges);


    console.log(total)

    return (
        <Helmet title="Checkout">
            <section className='bg-color checkout'>
                <Container>
                    <Row>
                        <Col md={6} sm={12} className='form-grid'>
                            <div className='form-col float-end'>
                                <Elements stripe={stripePromise}>
                                    <CheckoutForm 
                                        setShippingCharges={setShippingCharges}
                                        shippingCharges={shippingCharges}
                                        shippingAddress={shippingAddress}
                                        setShippingAddress={setShippingAddress}
                                    />
                                </Elements>
                            </div>
                        </Col>
                        <Col md={3} sm={12} className='order-summary'>
                            <div className='form-section'>
                                <span className='form-subtitle'>
                                    <h6>Order Summary</h6>
                                </span>
                                <span className='fee-details'>
                                    <span className='price'>
                                        <p>Subtotal</p>
                                        <p>{subTotal.toFixed(2)} USD</p>
                                    </span>
                                    <span className='price'>
                                        <p>Shipping Charges</p>
                                        <p>{(Number(shippingCharges) || 0).toFixed(2)} USD</p>
                                    </span>
                                    <span className='price'>
                                        <p>Total</p>
                                        <p>{Number(total.toFixed(2))} USD</p>
                                    </span>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};

export default Checkout;
